.SideBar{
    width: 20%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: var(--light-background-color);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    border-right: solid 1px rgb(81, 78, 78);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    transition: 0.8s;
    .Hamburger{
        position: absolute;
        right: -22%;
        top: 2%;
        height: 50px;
        width: 50px;
        z-index: 9999;
        margin: 0;
        padding: 15px;
        display: none;
        cursor: pointer;
        transition: all .5s;
        span {
            position: absolute;
            left: 0;
            right: 0;
            display: block;
            width: 100%;
            height: 2px;
            background-color: white;
            border-radius: 1px;
            transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);
            background-image: linear-gradient(144deg, var(--color-2), var(--color-1) 50%,var(--color-3));
            border-radius: 3px;
            &:first-of-type {
                top: 15px;
            }   
            &:last-of-type {
                bottom: 15px;
            }
        }
        &:active {
            transform: scale(0.8);
          }
    }
    .Divider{
        display: block;
        width: 70%;
        height: 2px;
        margin-left: 15%;
        background-image: linear-gradient(144deg, var(--color-2), var(--color-1) 50%,var(--color-3));
        border-radius: 1px;
    }
    .DailyResult{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        margin: 0;
        padding: 0;
        .Leaderboard{
            margin: auto;
            width: 95%;
            height: 80%;
            padding: 0;
        }
    }
    .Solution{
        p{
            text-align: justify;
            padding: 5%;
            .AllAnswer{
                cursor: pointer;
                padding-top: 5%;
                font-weight: bold;
                .Answer{
                    display: none;
                }
                .HiddenAnswer{
                    display: inline;
                }
                &:hover .Answer{
                    animation: fadeIn 2s;
                    display: inline;
                }
                &:hover .HiddenAnswer{
                    display: none;
                }
            }
        }
        li{
            list-style-type: none;
            padding: 2%;
        }
    }
    .Connection{
        height: 20%;
        display: flex;
        flex-direction: column;
        .ButtonPart{
            display: flex;
            justify-content: space-around;
            margin-top: 5%;
        }
    }
}
@media screen and (max-width:1100px) {
    .SideBar{
        width: 45%;
        left: -45%;
        .Hamburger{
            display: block;
        }
    }
    .SideBar.active {
        left: 0;
        .Hamburger{
            right: 5%;
            span {
                &:first-of-type {
                    top: 6px;
                    transform: rotate(45deg);
                    transform-origin: top left;
                }   
                &:last-of-type {
                    bottom: 7px;
                    transform: rotate(-45deg);
                    transform-origin: bottom left;
                }
            }
        }
    }
}

@media screen and (max-width:540px) {
    .SideBar{
        width: 80%;
        left: -80%;
    }
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }