:root {
  --background-color: #1B1B1D;
  --light-background-color: #39393c;
  --font-color: white;
  --color-1: #308355;
  --color-2: #3CB371;
  --color-3: #00FA9A;
}

body {
    margin: 0;
    padding: 0;
    background-color: var(--background-color);
    color: var(--font-color);
    font-family: 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    h1, p{
        padding:0;
        margin:0;
    }
}
.Header{
  color: var(--background-color);
}

@font-face{
  font-family: 'digital-7';
  src: url('./assets/digital-7.ttf') format('truetype');
}

.App {
  padding:0;
  margin:0;
  width: 100vw;
  height: 100vh;
  position: relative;
}

*{
  box-sizing: border-box;
  -webkit-font-smoothing: inherit;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.Loading-Screen {
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 250;
  background-color: var(--background-color);
  overflow: hidden;
}

.Loader  {
  animation: rotate 1s infinite;  
  height: 50px;
  width: 50px;
  &:before,&:after {   
      border-radius: 50%;
      content: '';
      display: block;
      height: 20px;  
      width: 20px;
  }
  &:before {
      animation: ball1 1s infinite;  
      background-color: var(--color-3);
      box-shadow: 30px 0 0 var(--color-2);
      margin-bottom: 10px;
  }
  &:after {
      animation: ball2 1s infinite; 
      background-color: var(--color-3);
      box-shadow: 30px 0 0 var(--color-1);
  }

  @keyframes rotate {
      0% { 
      -webkit-transform: rotate(0deg) scale(0.8); 
      -moz-transform: rotate(0deg) scale(0.8);
      }
      50% { 
      -webkit-transform: rotate(360deg) scale(1.2); 
      -moz-transform: rotate(360deg) scale(1.2);
      }
      100% { 
      -webkit-transform: rotate(720deg) scale(0.8); 
      -moz-transform: rotate(720deg) scale(0.8);
      }
  }
  @keyframes ball1 {
      0% {
      box-shadow: 30px 0 0 var(--color-2);
      }
      50% {
      box-shadow: 0 0 0 var(--color-2);
      margin-bottom: 0;
      -webkit-transform: translate(15px,15px);
      -moz-transform: translate(15px, 15px);
      }
      100% {
      box-shadow: 30px 0 0 var(--color-2);
      margin-bottom: 10px;
      }
  }
  @keyframes ball2 {
      0% {
      box-shadow: 30px 0 0 var(--color-1);
      }
      50% {
      box-shadow: 0 0 0 var(--color-1);
      margin-top: -20px;
      -webkit-transform: translate(15px,15px);
      -moz-transform: translate(15px, 15px);
      }
      100% {
      box-shadow: 30px 0 0 var(--color-1);
      margin-top: 0;
      }
  }
}

a{
  text-decoration: none;
  color: white;
  border-bottom: 2px solid rgba(255, 255, 255, 0.427);
}

a:hover{
  border-bottom-color: white;
}

input{
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 10px;
  &::placeholder{
      color: var(--color-2);
  }
}
button{
  font-size: small;
  background-color: var(--background-color);
  color: white;
  text-decoration: none;
  width: 40%;
  padding: 12px;
  border: none;
  border-radius: 10px;
  transition: transform .1s;
  cursor: pointer;
  user-select: none;
  touch-action: none;
  border-bottom: none;
  &:hover{
      background-color: white;
      color: var(--background-color);
  }
  &:active{
      transform: scale(0.95);
  }
}
.ErrorMessage{
  font-size: small;
  color: red;
  display: block;
}
.Exit{
  position: absolute;
  right: 0;
  top: 0;
  padding: 0;
  margin: 2%;
  cursor: pointer;
  height: 40px;
  width: 40px;
  transition: transform .2s;
  span{
      display: block;
      padding: 0;
      margin: 0;
      position: absolute;
      width: 141%;
      height: 3px;
      background-image: linear-gradient(144deg, var(--color-2), var(--color-1) 50%,var(--color-3));
      border-radius: 3px;
      &:first-of-type {
          top: -3.5%;
          transform: rotate(45deg);
          transform-origin: top left;
      }   
      &:last-of-type {
          bottom: 0;
          transform: rotate(-45deg);
          transform-origin: bottom left;
      }
  }
  &:active {
      transform: scale(0.9);
  }
}
.NotFound{
  position: absolute;
  top: 40%;
  width: 100%;
  text-align: center;
  h1{
    margin-bottom: 2%;
  }
}
.BackWindow{
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.8);
  align-items: center;
  display: flex;
  .SignInBox{
    position: relative;
    margin: auto;
    padding: 2%;
    height: 40%;
    width: 40%;
    background-color: var(--light-background-color);
    border-radius: 15px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .SignInInputs{
        margin: auto;
        width: 60%;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        input{
            margin: 3%;
        }
    }
  }
}
