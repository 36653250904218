.Leaderboard{
    height: 65%;
    display: flex;
    flex-direction: column;
    padding: 8%;
    padding-bottom: 0%;
    table{
        table-layout: fixed;
        height: auto;
        thead{
            justify-content: space-around;
            align-items: center;
            th{
                padding: 10px;
                vertical-align:middle;
                text-align: center;
                font-weight: 500;
                font-size: 12px;
                color: #fff;
                text-transform: uppercase;
                &:first-of-type{
                    width: 40%;
                }
                &:last-of-type{
                    width: 10%;
                }
              }
        }
        tbody{
            align-items: center;
            td{
                padding: 10px 15px;
                vertical-align: middle;
                text-align: center;
                font-weight: 300;
                font-size: 12px;
                color: #fff;
                border-bottom: solid 1px rgba(255,255,255,0.1);
            }
            .UsernameCell{
                    width: 60%;
            }
            .RankCell{
                    width: 10%;
            }
            .ScoreCell{
                    width: 30%;
            }
        }
    }
    .TableHeader{
        background-color: rgba(255,255,255,0.3);
    }
    .TableContent{
        overflow-x:auto;
        margin-top: 0px;
        border: 1px solid rgba(255,255,255,0.3);
        min-height: 120px;
    }
}