.Riddle{
    width: 60%;
    height: 50%;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 10%;
    left: 30%;
    display: block;
    h2{
        text-align: center;
        span{
            font-weight: 300;
        }
    }
    .QuestionTooltip{
        position: absolute;
        top: 0;
        right: 0; 
        .TooltipText {
            visibility: hidden;
            width: 200px;
            right: 55px;
            top: -10px;
            background-color: white;
            color: black;
            text-align: center;
            padding: 5px 0;
            border-radius: 6px;
            position: absolute;
            z-index: 1;
        }
        .QuestionLogo{
            width: 40px;
            height: 40px;
            cursor: pointer;
        }
        &:hover .TooltipText {
            visibility: visible;
        }
    }
    .Congrats{
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p{
            margin: 3%;
        }
        a{
            cursor: pointer;
            transition: transform .1s;
            &:active{
                transform: rotate(0.95);
                color: var(--color-3);
            }
        }
    }
    .MainSpace, .Congrats{
        justify-content: space-around;
        height: 100%;
        .ErrorMessage{
            margin-left: 8%;
            margin-top: 5%;
            text-align: center;
        }
        .RiddleText{
            height: auto;
            margin: 0;
            padding: 12%;
            font-size: calc(100% + 0.4vw);
            font-weight: 350;
            width: 100%;
            text-align: center;
            overflow-x: hidden;
        }
        .MainButton{
            background-image: linear-gradient(144deg, var(--color-2), var(--color-1) 50%,var(--color-3));
            border: 0;
            transition: transform .1s;
            border-radius: 10px;
            color: white;
            display: flex;
            font-size: calc(100% + 0.4vw);
            justify-content: center;
            line-height: 1em;
            max-width: 100%;
            min-width: 140px;
            padding: 2px;
            text-decoration: none;
            user-select: none;
            -webkit-user-select: none;
            touch-action: manipulation;
            white-space: nowrap;
            cursor: pointer;
            span {
                background-color: var(--background-color);
                padding: 12px 22px;
                border-radius: 10px;
                width: 100%;
                height: 100%;
                transition: 300ms;
                }
            &:active{
                transform: scale(0.9);
            }
            &:hover span {
                background: none;
            }
        }
        .RiddleForm[animation="1"]{
            animation: shake 0.62s cubic-bezier(.36,.07,.19,.97) both;
        }
        .RiddleForm, .NameForm{
            width: 100%;
            height: 30%;
            display: flex;
            justify-content: space-around;
            button{
                height: fit-content;
            }
            .RiddleInput{
                width: 50%;
                &::after,
                &::before {
                margin: 0;
                padding: 0;
                }
        
                .FormLabel {
                    margin-left: 2rem;
                    margin-top: 0.7rem;
                    display: block;
                    transition: all 0.3s;
                    transform: translateY(0rem);
                    color: var(--color-2);
                    font-size: calc(100% + 0.3vw);
                }
    
                .FormInput {
                    color: #333;
                    font-size: calc(100% + 0.3vw);
                    margin: 0;
                    width: 100%;
                    padding: 1rem 2rem;
                    border-radius: 0.4rem;
                    background-color: white;
                    border: none;
                    border-bottom: 0.3rem solid transparent;
                    transition: all 0.3s;
                    &:placeholder-shown + .FormLabel {
                        opacity: 0;
                        visibility: hidden;
                        -webkit-transform: translateY(-4rem);
                        transform: translateY(-4rem);
                    }
                    &::placeholder{
                        color: var(--color-2);
                    }
                }
            }
        }
        .NameForm{
            height: 20%;
        }
    }
}

@media screen and (max-width:1100px) {
    .Riddle{
        width: 84%;
        left: 8%;
        font-size: medium;
        .MainSpace, .Congrats{
            font-size: calc(90% + 0.2vw);
        }
        .QuestionTooltip{
            top: -8%;
            right: 5%; 
        }
    }
}

@media screen and (max-width:700px) {
    .Riddle{
        height: 70%;
        width: 100%;
        left: 0;
    }

    .MainSpace, .Congrats{
        font-size: calc(80% + 0.1vw);
    }
    .RiddleText{
        justify-content: center;
        display: flex;
        p{
            margin: auto;
        }
    }
    .RiddleForm, .NameForm{
        height: 25%;
        align-items: center;
        flex-direction: column;
        margin: auto;
        .RiddleInput{
            width: 85%;
        }
        .MainButton{
            width: 50%;
            margin-top: 5%;
        }
    }
}
  
@keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }