.FriendInfo{
    height: 45%;
    display: flex;
    flex-direction: column;
    .FriendTable{
        margin-top: 5%;
        margin-left: 6%;
        height: 50%;
        width: 88%;
    }
    table{
        table-layout: fixed;
        height: fit-content;
        th{
            padding: 10px 20px;
            text-align: left;
            font-weight: 500;
            font-size: 12px;
            color: #fff;
            text-transform: uppercase;
          }
          td{
            padding: 10px 15px;
            width: 100%;
            text-align: center;
            vertical-align:middle;
            font-weight: 300;
            font-size: 12px;
            color: #fff;
            border-bottom: solid 1px rgba(255,255,255,0.1);
          }
          .XCell{
            padding: 10px 8px;
          }
          .UsernameCell{
            padding: 0;
          }
    }
    img{
        height: 15px;
    }
    .TableHeader{
        background-color: rgba(255,255,255,0.3);
    }
    .TableContent{
        height: 95%;
        overflow-x:auto;
        margin-top: 0px;
        border: 1px solid rgba(255,255,255,0.3);
    }
    .AddFriend{
        margin: 15%;
        margin-bottom: 2%;
        width: 70%;
        align-items: center;
        position: relative;
        input{
            width: 100%;
            padding: 12px;
            border: none;
            border-radius: 10px;
            &::placeholder{
                color: var(--color-2);
            }
        }
        button{
            background-color: var(--background-color);
            color: white;
            text-decoration: none;
            position: absolute;
            right: 1%;
            top: 5%;
            padding: 10px;
            border: none;
            border-radius: 10px;
            transition: transform .1s;
            cursor: pointer;
            &:hover{
                background-color: white;
                //border: solid 1px var(--background-color);
                color: var(--background-color);
            }
            &:active{
                transform: scale(0.95);
            }
        }
    }
}