.Contact{
    margin: 0;
    padding: 0;
    z-index: 9;
    position: absolute;
    right: 2%;
    bottom: 2%;
    width: 350px;
    display: flex;
    justify-content: space-around;
    .ContactDiv{
        width: 160px;
        height: 120px;
        text-align: center;
        background-color: var(--color-2);
        border-radius: 10px;
        font-size: small;
        div{
            position: relative;
            display: flex;
            top: -2vh;
            left: 35%;
            width: 30%;
            height: 40%;
            border-radius: 10px;
            background-color: white;
            margin: 0;
            img{
                margin: auto;
                width: 45%;
            }
            .Twitter-logo{
                width: 55%;
            }
        }
        .Mail{
            border: 3px solid var(--color-2);
        }
        p{
            margin-top: -9%;
        }
        a{
            display: flex;
            background-color: var(--background-color);
            color: white;
            text-decoration: none;
            width: 78%;
            min-width: 100px;
            height: 35%;
            border-radius: 10px;
            margin: auto;
            margin-top: 5%;
            transition: transform .1s;
            cursor: pointer;
            user-select: none;
            touch-action: none;
            border-bottom: none;
            &:hover{
                background-color: white;
                color: var(--background-color);
            }
            p{
                margin: auto;
                user-select: none;
                touch-action: none;
                &:hover{
                    text-decoration: bold;
                }
            }
            &:active{
                transform: scale(0.95);
            }
        }
        &:last-of-type{
            background-color: var(--color-2);
        }
    }
}

@media screen and (max-width:1100px) {
    .Contact{
        bottom: 5%;
        width: 100%;
        right: 0;

    }
}

@media screen and (max-height:640px) {
    .Contact{
        display: none;
    }
}

@media screen and (max-width:300px) {
    .Contact{
        display: none;
    }
}