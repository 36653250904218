.LeftTries{
    width: 60%;
    height: 10%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    font-weight: 300;
    font-size: calc(100% + 0.3vw);
    align-items: center;
    .Bubble{
        width: 20px;
        height: 20px;
        border-radius: 100%;
        background-color: var(--color-3);
        &[animation="1"]{
            animation: explode 1s;
            animation-fill-mode: forwards;
            @keyframes explode {
                0% {
                scale: 1.1;
                }
                70% {
                scale: 0.6;
                background-color: var(--color-3);
                }
                100% {
                scale: 1.8;
                background-color: #00fa9a00;
                }
            }
        } 
    }
}